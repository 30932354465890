export const activateSearch = 'Activate Search'
export const deactivateSearch = 'Deactivate Search'
export const incrementActivePostIndex = 'Increment Active Post Index'
export const decrementActivePostIndex = 'Decrement Active Post Index'

export const updateSearchTermActionType = 'Update Search Term'
export const updateSearchTerm = (searchTerm, matchingPosts) => ({
  type: updateSearchTermActionType,
  searchTerm,
  matchingPosts,
})
