import * as actions from './actions'

export const initialState = {
  searchActive: false,
  searchTerm: '',
  activePostIndex: 0,
  matchingPosts: [],
  animationDirection: 'down',
}

export function reducer(state, action) {
  const actionType = typeof action === 'string' ? action : action.type

  switch (actionType) {
    case actions.activateSearch:
      return { ...state, searchActive: true }
    case actions.deactivateSearch:
      return { ...state, searchActive: false, searchTerm: '' }
    case actions.updateSearchTermActionType:
      return {
        ...state,
        searchTerm: action.searchTerm,
        matchingPosts: action.matchingPosts,
        activePostIndex: 0
      }
    case actions.incrementActivePostIndex:
      return onIncrementActivePostIndex(state)
    case actions.decrementActivePostIndex:
      return onDecrementActivePostIndex(state)
    default:
      return { ...state }
  }
}

function onIncrementActivePostIndex(state) {
  const activePostIndex =
    state.activePostIndex + 1 < state.matchingPosts.length
      ? state.activePostIndex + 1
      : state.activePostIndex
  return {
    ...state,
    activePostIndex,
    animationDirection: 'down',
  }
}

function onDecrementActivePostIndex(state) {
  const activePostIndex =
    state.activePostIndex === 0 ? 0 : state.activePostIndex - 1
  return {
    ...state,
    activePostIndex,
    animationDirection: 'up',
  }
}
